<template>
  <v-card v-if="record">
    <v-card-text
      class="text-caption"
    >
      <div class="record-header mb-2">
        <div class="mb-4 d-flex justify-space-between">
          <div>
            <v-chip
              small
              label
              class="white--text mr-2 px-2 mb-2"
              :class="colorClass"
            >
              <span v-if="showRecordWarning" class="mr-1">⚠️</span>
              <span>{{ recordType }}</span>
            </v-chip>
          </div>

          <div v-if="editable">
            <v-btn
              @click="removeRecord"
              icon
              small
            >
              <v-icon v-if="!isReadyToDelete" class="red--text" small>fa fa-trash</v-icon>
              <v-icon v-if="isReadyToDelete" small>fa fa-times</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- 開銷類型 -->
        <v-select
          v-if="editable && record.record_type === 'cost'"
          v-model="record.type"
          :items="recordTypes"
          :disabled="isReadyToDelete"
          label="開銷類型"
          item-text="text"
          item-value="value"
          dense
          outlined
          hide-details
          class="mb-4 text-caption">
        </v-select>
        
        <!-- 收入類型 -->
        <v-radio-group
          v-if="editable && record.record_type === 'income'"
          v-model="record.master"
          :disabled="isReadyToDelete"
          row
          dense
          hide-details
          class="mb-2 text-caption"
        >
          <v-radio
            v-for="type in [true, false]"
            :key="type"
            :value="type"
            class="mr-2"
            :label="$t(`income.master.${type}`)"
            dense
            hide-details
          ></v-radio>
        </v-radio-group>
      </div>

      <div class="record-body mb-4 d-flex" v-if="!editable">
        <div class="record-name text-truncate">{{ record.name }}</div>
        <div>{{ record.amount }}</div>
      </div>

      <div class="record-body mb-4 d-flex" v-if="editable">
        <v-text-field
          v-model="record.name"
          label="名稱"
          :disabled="isReadyToDelete"
          dense
          outlined
          hide-details
          class="pr-2 text-caption"
        ></v-text-field>

        <v-text-field
          v-model="record.amount"
          label="金額"
          type="number"
          :disabled="isReadyToDelete"
          dense
          outlined
          hide-details
          class="pl-2 text-caption"
        ></v-text-field>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import accountingConstants from 'modules/accounting/config/accountingConstants'
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
    },
    index: {
      type: Number,
      required: true,
    },
    deleteIds: {
      type: Array,
    },
  },
  data: () => ({
    record: null,
  }),
  methods: {
    removeRecord() {
      this.$emit('removeRecord', {
        record: window.eagleLodash.cloneDeep(this.record),
        index: this.index,
      })
    },
  },
  computed: {
    isReadyToDelete() {
      if(!Array.isArray(this.deleteIds)) return false
      return this.deleteIds.indexOf(this.record.id) > -1
    },
    recordType() {
      const recordType = this.$t(`record.type.${this.record.record_type}`)
      if (this.record.record_type != 'cost') {
        return recordType
      }
      const costType = this.$t(`cost.type.${this.record.type}`)
      return `${recordType}(${costType})`
    },
    colorClass() {
      return this.$helper.getAccountingRecordTypeColorClass(this.record.record_type)
    },
    recordTypes() {
      return accountingConstants.COST_TYPES.map(type => ({
        text: this.$t(`cost.type.${type}`),
        value: type,
      }))
    },
    ...mapGetters({
      siteConfig: 'base/siteConfig',
    }),
    warningAmountSingle() {
      return this.siteConfig.warningAmountSingle
    },
    showRecordWarning() {
      if (this.record.record_type != 'cost') {
        return false
      }
      return this.record.amount >= this.warningAmountSingle
    },
  },
  watch: {
    record: {
      deep: true,
      handler() {
        this.$emit('input', window.eagleLodash.cloneDeep(this.record))
      },
    },
    value: {
      immediate: true,
      handler(value) {
        if (window.eagleLodash.isEqual(value, this.record)) {
          return
        }
        this.record = window.eagleLodash.cloneDeep(value)
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
.record-body
  .record-name
    width: 70%
</style>